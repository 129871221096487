import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Helmet } from "react-helmet"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Helmet>
        <link rel="canonical" href="https://www.collectivetops.com/blog" />
        <title>Blog | Collective Tops</title>
        <meta name="description" content='Explore the freshest dress trends on our blog, highlighting chic midi, playful mini, elegant maxi, and breath-taking formal wear. Gain inspiration for outfits, receive expert styling advice, and navigate fashion for every event. Amplify your style with our varied dress collection.'/>      
      </Helmet>
      <div
        className="blog-list-container grid grid-cols-2 gap-4 mt-8"
        style={{ listStyle: `none` }}
      >
        {posts.map(post => {
          const title = post.frontmatter.title || post.fields.slug

          return (
            <div className="blog-list" key={post.fields.slug}>
              <article
                className="post-list-item"
                itemScope
                itemType="http://schema.org/Article"
              >
                <header>
                  <h2>
                    <Link
                      className="text-lg font-bold no-underline text-black hover:underline"
                      to={post.fields.slug}
                      itemProp="url"
                    >
                      <img
                        className="max-w-sm mx-5 hover:opacity-80"
                        src={post.frontmatter.frontimage}
                      />
                      <span itemProp="headline">{title}</span>
                    </Link>
                  </h2>
                  <small>{post.frontmatter.date}</small>
                </header>
                <section>
                  <p
                    className="max-w-xl"
                    dangerouslySetInnerHTML={{
                      __html: post.frontmatter.description || post.excerpt,
                    }}
                    itemProp="description"
                  />
                </section>
              </article>
            </div>
          )
        })}
      </div>
    </Layout>
  )
}

export default BlogIndex

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="All posts" />

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          frontimage
        }
      }
    }
  }
`
